import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import git from "../images/github.svg"
import linkedIn from "../images/linkedin.svg"
import mail from "../images/mail.svg"

const links = [
  {
    imgSrc: git,
    href: "https://github.com/vobango",
    title: "Github",
  },
  {
    imgSrc: linkedIn,
    href: "https://linkedin.com/in/kaspararme/",
    title: "LinkedIn",
  },
  {
    imgSrc: mail,
    href: "mailto:kaspar.arme@reach-u.com",
    title: "Send me an e-mail",
    target: "_self",
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="KA" keywords={[`homepage`, `react`]} />
    <h1>Kaspar Arme</h1>
    <p>
      Web Developer @{" "}
      <a
        href="https://www.reach-u.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ whiteSpace: "nowrap" }}>Reach U</span>
      </a>
    </p>
    <footer>
      {links.map(({ imgSrc, href, title, target = "_blank" }) => (
        <a
          key={title}
          href={href}
          target={target}
          title={title}
          rel="noopener noreferrer"
        >
          <img src={imgSrc} alt={title} style={{ margin: "8px" }} />
        </a>
      ))}
    </footer>
  </Layout>
)

export default IndexPage
